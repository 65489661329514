import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './LanguagePage.css';
import { AuthContext } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faHistory, faComments } from '@fortawesome/free-solid-svg-icons';
import WordPackIntroModal from '../../components/WordPackIntroModal/WordPackIntroModal';
import WordPackModal from '../../components/WordPackModal/WordPackModal';
import ContextSelectionModal from '../../components/ContextSelectionModal/ContextSelectionModal';
import openAIService from '../../services/openAIService';
import DailyLimitModal from '../../components/DailyLimitModal/DailyLimitModal';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const languageImages = {
  Spanish: {
    flag: '/images/mexico_flag.png',
    cover: '/images/spain_cover.jpg',
  },
  Japanese: {
    flag: '/images/icon_flag-ja.svg',
    cover: '/images/japanese-cover.webp',
  },
  Portuguese: {
    flag: '/images/icon_flag-pt.svg',
    cover: '/images/portugese-cover.jpg',
  },
  French: {
    flag: '/images/icon_flag-fr.svg',
    cover: '/images/french-cover.jpg',
  },
  German: {
    flag: '/images/icon_flag-de.svg',
    cover: '/images/german-cover.jpg',
  },
  Italian: {
    flag: '/images/icon_flag-it.svg',
    cover: '/images/italian-cover.jpg',
  },
  Mandarin: {
    flag: '/images/icon_flag-zh.svg',
    cover: '/images/mandarin-cover.jpg',
  },
  Arabic: {
    flag: '/images/icon_flag-ar.svg',
    cover: '/images/arabic-cover.jpg',
  },
  Cantonese: {
    flag: '/images/icon_flag-hk.png',
    cover: '/images/cantonese-cover.jpg',
  },
  Korean: {
    flag: '/images/icon_flag-ko.svg',
    cover: '/images/korean-cover.jpg',
  },
  Russian: {
    flag: '/images/icon_flag-ru.svg',
    cover: '/images/russian-cover.jpg',
  },
  Tagalog: {
    flag: '/images/icon_flag_pp.png',
    cover: '/images/tagalog-cover.jpg',
  },
  Greek: {
    flag: '/images/icon_flag_gr.png',
    cover: '/images/greek-cover.jpg',
  },
  Hindi: {
    flag: '/images/icon_flag_in.png',
    cover: '/images/hindi-cover.jpg',
  },
};

const getLanguageKey = (language) => {
  return language ? language.toLowerCase() : '';
};

// Helper function to get translation parameters based on UI language
const getTranslationParams = (currentLanguage) => {
  const parameterMap = {
    es: { level: 'nivel', language: 'idioma' },
    fr: { level: 'niveau', language: 'langue' },
    it: { level: 'livello', language: 'lingua' },
    pt: { level: 'nível', language: 'idioma' },
    
    // Germanic languages
    de: { level: 'stufe', language: 'sprache' },
    
    // Slavic languages
    ru: { level: 'уровень', language: 'язык' },
    
    // Greek
    el: { level: 'επίπεδο', language: 'γλώσσα' },
    
    // East Asian languages
    ja: { level: 'レベル', language: '言語' },
    ko: { level: '수준', language: '언어' },
    zh: { level: '当前级别', language: '语言' },     // Mandarin
    'zh-CN': { level: '当前级别', language: '语言' }, // Mainland China
    'zh-HK': { level: '當前級別', language: '語言' }, // Hong Kong/Cantonese
    'zh-TW': { level: '當前級別', language: '語言' }, // Taiwan
    
    // South Asian languages
    hi: { level: 'स्तर', language: 'भाषा' },
    
    // Semitic languages
    ar: { level: 'مستوى', language: 'لغة' },
    
    // Austronesian languages
    tl: { level: 'antas', language: 'wika' }, // Tagalog
  };

  // Get the base language code or full code
  return parameterMap[currentLanguage] || 
         parameterMap[currentLanguage.split('-')[0]] || 
         { level: 'level', language: 'language' };
};

const videosByLanguage = {
  spanish: [
    { id: 'CTHoo_eIiIA', language: 'spanish' }, // Easy Spanish: Basic Spanish Phrases
    { id: 'ybfyLfI5Ml0', language: 'spanish' }, // Easy Spanish: Street Interviews
    { id: 'hpsgQkD27BA', language: 'spanish' }  // Easy Spanish: Daily Routine
  ],
  french: [
    { id: 'CmTYhv2F96k', language: 'french' },  // Easy French: Basic French Phrases
    { id: 'aMx0d42wzBs', language: 'french' },  // Easy French: Street Interviews Paris
    { id: 'w_9ynCt9nLc', language: 'french' }   // Easy French: Daily Life
  ],
  german: [
    { id: 'an232kkTEMk', language: 'german' },  // Easy German: Basic Phrases
    { id: 'G-WTBhyoK7o', language: 'german' },  // Easy German: Street Interviews Berlin
    { id: 'TDRNIkVE4bw', language: 'german' }   // Easy German: Daily Life
  ],
  italian: [
    { id: 'GFlG9liLM-4', language: 'italian' }, // Easy Italian: Basic Phrases
    { id: 'q2bUdfFVllI', language: 'italian' }, // Easy Italian: Street Interviews Rome
    { id: '8Pmz1TZ6OLc', language: 'italian' }  // Easy Italian: Daily Life
  ],
  portuguese: [
    { id: 'HWusYk1PGYY', language: 'portuguese' }, // Easy Portuguese: Basic Phrases
    { id: 'UcrxqVirLRU', language: 'portuguese' }, // Easy Portuguese: Street Interviews
    { id: 'CG-VLGlG7TY', language: 'portuguese' }  // Easy Portuguese: Daily Life
  ],
  japanese: [
    { id: 'JYEutL_zQQc', language: 'japanese' }, // Easy Japanese: Basic Phrases
    { id: 'kwMiWJAR6Ag', language: 'japanese' }, // Easy Japanese: Street Interviews Tokyo
    { id: 'tupRbmVM9Wc', language: 'japanese' }  // Easy Japanese: Daily Life
  ],
  mandarin: [
    { id: 'eKWrEV1fawc', language: 'mandarin' }, // Easy Mandarin: Basic Phrases
    { id: 'KLe98XxzDSg', language: 'mandarin' }, // Easy Mandarin: Street Interviews
    { id: 'A-oPzWRqtDw', language: 'mandarin' }  // Easy Mandarin: Daily Life
  ],
  korean: [
    { id: 'in3QD4BzUB4', language: 'korean' },   // Easy Korean: Basic Phrases
    { id: 'Aeufi2J3m6o', language: 'korean' },   // Easy Korean: Street Interviews Seoul
    { id: 'HjutPoNDVrI', language: 'korean' }    // Easy Korean: Daily Life
  ],
  russian: [
    { id: 'prSfxdmjNzE', language: 'russian' },  // Easy Russian: Basic Phrases
    { id: 'AlUMYm5YExA', language: 'russian' },  // Easy Russian: Street Interviews
    { id: 'P2O8HWoMEX0', language: 'russian' }   // Easy Russian: Daily Life
  ],
  arabic: [
    { id: 'NQnsUR6FFFg', language: 'arabic' },   // Easy Arabic: Basic Phrases
    { id: 'oDnbqK34pas', language: 'arabic' },   // Easy Arabic: Street Interviews
    { id: 'cT9aDNrqe30', language: 'arabic' }    // Easy Arabic: Daily Life
  ],
  greek: [
    { id: '8VKdTtEm_EI', language: 'greek' },
    { id: 'm0RH3xg_WiQ', language: 'greek' },
    { id: 'i9ofxsTm0sI', language: 'greek' }
  ],
  cantonese: [
    { id: 'm03X0MZBQzI', language: 'cantonese' },
    { id: 'lsqmMsH-cTs', language: 'cantonese' },
    { id: 'QelN8DL_6yU', language: 'cantonese' }
  ],
  tagalog: [
    { id: 'COCDy-fArT', language: 'tagalog' },
    { id: 'UOoRkoYLIpQ', language: 'tagalog' },
  ],
  hindi: [
    { id: 'uz08VvPEC-o', language: 'hindi' },
    { id: 'YCkY_yo_wgU', language: 'hindi' },
  ],
  // If no specific videos for a language, use trending videos as fallback
  default: [
    { id: 'CTHoo_eIiIA', language: 'spanish' },
    { id: 'CmTYhv2F96k', language: 'french' },
    { id: 'KLe98XxzDSg', language: 'german' }
  ]
};

function LanguagePage() {
  const { t, i18n } = useTranslation();
  const currentUILanguage = i18n.language;
  const currentLanguageParams = getTranslationParams(currentUILanguage);
  const { language } = useParams();
  const { user } = useContext(AuthContext);
  const [level, setLevel] = useState('');
  const [userLanguageLevel, setUserLanguageLevel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [vocabGamesPlayed, setVocabGamesPlayed] = useState(0);
  const [storyChainGamesPlayed, setStoryChainGamesPlayed] = useState(0);
  const [isOpeningPack, setIsOpeningPack] = useState(false);
  const [showPackModal, setShowPackModal] = useState(false);
  const [currentPhrases, setCurrentPhrases] = useState([]);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [hasSeenIntro, setHasSeenIntro] = useState(() => {
    const seen = localStorage.getItem('wordPackIntroSeen');
    return seen === 'true';
  });
  const [isClosing, setIsClosing] = useState(false);
  const [pastPacks, setPastPacks] = useState([]);
  const [showContextModal, setShowContextModal] = useState(false);
  const [selectedContext, setSelectedContext] = useState('');
  const [showDailyLimitModal, setShowDailyLimitModal] = useState(false);
  const [nextAvailableTime, setNextAvailableTime] = useState(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const navigate = useNavigate();

  const languageData = languageImages[language.charAt(0).toUpperCase() + language.slice(1).toLowerCase()] || {};

  // Get current videos and create thumbnail URLs
  const currentVideos = videosByLanguage[language.toLowerCase()] || videosByLanguage.default;
  const videoIds = currentVideos.map(video => video.id);
  const videoThumbnails = videoIds.map((id) => `https://img.youtube.com/vi/${id}/maxresdefault.jpg`);

  useEffect(() => {
    if (user && user.id) {
      const fetchUserLanguageLevel = async () => {
        try {
          const response = await fetch(`${apiUrl}/user_language_levels?user_id=${user.id}`);
          const data = await response.json();
          if (data.success) {
            const userLevel = data.language_levels.find(l => l.language.toLowerCase() === language.toLowerCase());
            setUserLanguageLevel(userLevel ? userLevel.level : null);
          } else {
            console.error('Failed to fetch user language levels:', data.message);
          }
        } catch (error) {
          console.error('Error fetching user language levels:', error);
        } finally {
          setLoading(false);
        }
      };

      const fetchGamesPlayedByLanguage = async (gameName, language, setGamesPlayedState) => {
        try {
          const response = await fetch(`${apiUrl}/games_played/${user.id}/${gameName}/${language}`);
          const data = await response.json();
          if (data.success) {
            setGamesPlayedState(data.games_played);
          } else {
            console.error(`Failed to fetch ${gameName} games played:`, data.message);
          }
        } catch (error) {
          console.error(`Error fetching ${gameName} games played:`, error);
        }
      };

      fetchUserLanguageLevel();
      fetchGamesPlayedByLanguage('Vocabulary Race', language, setVocabGamesPlayed);
      fetchGamesPlayedByLanguage('Story Chain', language, setStoryChainGamesPlayed);
    }
  }, [user, language]);

  useEffect(() => {
    if (user && user.id) {
      const fetchPastPacks = async () => {
        try {
          const response = await fetch(`${apiUrl}/word_packs/${user.id}/${language}`);
          const data = await response.json();
          if (data.success) {
            setPastPacks(data.packs);
          }
        } catch (error) {
          console.error('Error fetching past packs:', error);
        }
      };
      fetchPastPacks();
    }
  }, [user, language]);

  useEffect(() => {
    document.body.classList.add('language-page-body');
    return () => {
      document.body.classList.remove('language-page-body');
    };
  }, []);

  const handleLevelChange = (selectedLevel) => {
    setLevel(selectedLevel);

    if (!user || !user.id) {
      console.error('User not logged in');
      return;
    }

    fetch(`${apiUrl}/update_language_level`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: user.id,
        language: language.charAt(0).toUpperCase() + language.slice(1),
        level: selectedLevel,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log('Language level updated successfully', data);
          setUserLanguageLevel(selectedLevel);
        } else {
          console.error('Failed to update language level', data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleConnect = () => {
    navigate(`/connect/${language}`);
  };

  const handleChat = () => {
    navigate(`/chat/${language}`);
  };

  const handleVocabRace = () => {
    navigate(`/vocabularyrace/${language}`);
  };

  const handleStoryChain = () => {
    navigate(`/storychain/${language}`);
  };

  const handleOpenPack = async () => {
    if (isOpeningPack || isClosing) return;

    try {
      if (!user.is_pro) {
        const response = await fetch(
          `${apiUrl}/word_packs/can_generate?user_id=${user.id}&language=${language}`
        );
        const data = await response.json();

        if (!response.ok || !data.success) {
          throw new Error('Failed to check pack availability');
        }

        if (!data.can_generate) {
          const nextAvailable = new Date(data.next_available);
          const formattedTime = nextAvailable.toLocaleTimeString([], {
            hour: 'numeric',
            minute: '2-digit'
          });
          setNextAvailableTime(formattedTime);
          setShowDailyLimitModal(true);
          return;
        }
      }

      if (!hasSeenIntro) {
        setShowIntroModal(true);
        return;
      }

      setShowContextModal(true);
    } catch (error) {
      console.error('Error checking pack availability:', error);
      alert(t('languagePage.errors.pack.availability'));
    }
  };

  const handleContextSelection = async (context) => {
    setShowContextModal(false);
    setIsOpeningPack(true);
    setSelectedContext(context);
    
    try {
      const checkResponse = await fetch(
        `${apiUrl}/word_packs/can_generate?user_id=${user.id}&language=${language}`
      );
      const checkData = await checkResponse.json();

      if (!checkResponse.ok || !checkData.success) {
        throw new Error('Failed to check pack availability');
      }

      if (!checkData.can_generate) {
        const nextAvailable = new Date(checkData.next_available);
        const formattedTime = nextAvailable.toLocaleTimeString([], {
          hour: 'numeric',
          minute: '2-digit'
        });
        alert(`${checkData.message}\nNext available: ${formattedTime}`);
        return;
      }

      const phrases = await openAIService.generatePhrasesForContext(
        language,
        context,
        userLanguageLevel || 'A1'
      );
      
      if (!Array.isArray(phrases) || !phrases.every(p => p.phrase && p.translation)) {
        throw new Error('Invalid phrases format received');
      }
      
      const saveResponse = await fetch(`${apiUrl}/word_packs/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.id,
          language,
          phrases,
          level: userLanguageLevel || 'A1',
          context
        }),
      });
      
      if (!saveResponse.ok) {
        throw new Error('Failed to save word pack');
      }
      
      setCurrentPhrases(phrases);
      setShowPackModal(true);
      
      setPastPacks(prev => [{
        phrases,
        level: userLanguageLevel || 'A1',
        context,
        opened_at: new Date().toISOString()
      }, ...prev].slice(0, 10));
      
    } catch (error) {
      console.error('Error generating phrases:', error);
      alert(error.message || 'Sorry, there was an error opening the pack.');
    } finally {
      setIsOpeningPack(false);
    }
  };

  useEffect(() => {
    console.log('hasSeenIntro:', hasSeenIntro);
    console.log('showIntroModal:', showIntroModal);
  }, [hasSeenIntro, showIntroModal]);

  const handleStartConversation = () => {
    if (!userLanguageLevel) {
      alert(t('languagePage.conversation.selectLevel'));
      return;
    }
    navigate(`/conversation/${language}`, {
      state: { level: userLanguageLevel }
    });
  };

  const handleNextVideo = () => {
    setCurrentVideoIndex((prev) => 
      prev === (currentVideos.length - 1) ? 0 : prev + 1
    );
  };

  const handlePrevVideo = () => {
    setCurrentVideoIndex((prev) => 
      prev === videosByLanguage[language.toLowerCase()].length - 1 ? 0 : prev + 1
    );
  };

  if (loading) {
    return <div></div>;
  }

  return (
    <div className="language-page">
      <button 
        className="back-button fade-in" 
        onClick={() => navigate('/dashboard')}
      >
        {t('languagePage.buttons.back')}
      </button>
      
      <div
        className="cover-image fade-in"
        style={{ backgroundImage: `url(${languageData.cover})` }}
      ></div>
      
      <h1 className="fade-in">
        {t('languagePage.title', { 
          language: t(`languages.${getLanguageKey(language)}`, {
            defaultValue: language.charAt(0).toUpperCase() + language.slice(1).toLowerCase()
          })
        })}
      </h1>
      
      <div className="flag-container fade-in">
        <img src={languageData.flag} alt={`${language} flag`} className="language-flag" />
      </div>
      
      {userLanguageLevel ? (
        <div className="connect-section fade-in">
          <h2>
            {t('languagePage.level.current', { 
              level: userLanguageLevel,
              nivel: userLanguageLevel,      // Spanish
              niveau: userLanguageLevel,     // French
              livello: userLanguageLevel,    // Italian
              nível: userLanguageLevel,      // Portuguese
              stufe: userLanguageLevel,      // German
              уровень: userLanguageLevel,    // Russian
              επίπεδο: userLanguageLevel,    // Greek
              レベル: userLanguageLevel,      // Japanese
              수준: userLanguageLevel,       // Korean
              
              // Add Chinese variants
              当前级别: userLanguageLevel,    // Simplified Chinese
              當前級別: userLanguageLevel,    // Traditional Chinese/Cantonese
              Level: userLanguageLevel,      // Fallback
              
              // Dynamic parameter
              [currentLanguageParams.level]: userLanguageLevel
            })}
          </h2>
          <button className="connect-button" onClick={handleConnect}>
            {t('languagePage.buttons.videoChat')}
          </button>
          <button className="chat-button" onClick={handleChat}>
            {t('languagePage.buttons.chatRoom')}
          </button>
        </div>
      ) : (
        <div className="level-selection fade-in">
          <h2>{t('languagePage.level.select')}</h2>
          <p className="level-helper-text">{t('languagePage.level.helper')}</p>
          <div className="level-buttons">
            {['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((levelOption) => (
              <div key={levelOption} className="level-button-container">
                <button
                  className={`level-button ${level === levelOption ? 'selected' : ''}`}
                  onClick={() => handleLevelChange(levelOption)}
                >
                  {levelOption}
                </button>
                <div className="level-tooltip">
                  <strong>{t(`languagePage.level.descriptions.${levelOption}.name`)}</strong> - {t(`languagePage.level.descriptions.${levelOption}.description`)} ({t(`languagePage.level.descriptions.${levelOption}.vocabulary`)})
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="minigame-section fade-in">
        <h2 className="minigame-header">{t('languagePage.immersiveLearning.title')}</h2>
        <div className="minigame-card" onClick={handleVocabRace}>
          <div className="minigame-image">
            <img src="/images/vocab_race_icon_2.webp" alt={t("languagePage.vocab.race")} />
          </div>
          <div className="minigame-info">
            <div className="minigame-name">{t('languagePage.minigames.vocabRace.name')}</div>
            <div className="games-played-text">
              {t('languagePage.minigames.vocabRace.played', { count: vocabGamesPlayed })}
            </div>
          </div>
        </div>
        
        <div className="minigame-card" onClick={handleStoryChain}>
          <div className="minigame-image">
            <img src="/images/story_chain_icon.webp" alt={t("languagePage.story.chain")} />
          </div>
          <div className="minigame-info">
            <div className="minigame-name">{t('languagePage.minigames.storyChain.name')}</div>
            <div className="games-played-text">
              {t('languagePage.minigames.storyChain.played', { count: storyChainGamesPlayed })}
            </div>
          </div>
        </div>
        
        <div className="minigame-card coming-soon">
          <div className="coming-soon-banner">{t('languagePage.minigames.picturePerfect.comingSoon')}</div>
          <div className="minigame-image">
            <img src="/images/picture_perfect_icon.webp" alt={t("languagePage.pic.perfect")} />
          </div>
          <div className="minigame-info">
            <div className="minigame-name">{t('languagePage.minigames.picturePerfect.name')}</div>
            <div className="games-played-text">
              {t('languagePage.minigames.picturePerfect.played', { count: 0 })}
            </div>
          </div>
        </div>
      </div>

      <div className="comprehensible-input-container">
        <h2 className="comprehensible-input-header">
          {t('languagePage.comprehensibleInput.title')}
        </h2>
        <p className="comprehensible-input-description">
          {t('languagePage.comprehensibleInput.description')}
        </p>
        <div className="comprehensible-input-video-container">
          <img 
            src={videoThumbnails[currentVideoIndex]} 
            alt={t('languagePage.comprehensibleInput.videoAlt')} 
            className="comprehensible-input-thumbnail"
          />
          <div className="comprehensible-input-nav-arrows">
            <button 
              className="comprehensible-input-nav-button" 
              onClick={handlePrevVideo}
              aria-label="Previous video"
            >
              ←
            </button>
            <button 
              className="comprehensible-input-nav-button" 
              onClick={handleNextVideo}
              aria-label="Next video"
            >
              →
            </button>
          </div>
          <a 
            href={`https://www.youtube.com/watch?v=${videoIds[currentVideoIndex]}`}
            target="_blank"
            rel="noopener noreferrer"
            className="comprehensible-input-watch-button"
          >
            {t('languagePage.comprehensibleInput.watchButton')}
          </a>
        </div>
      </div>

      <div className="wordpacks-section">
        <div className="wordpack-container">
          <h2 className="wordpacks-header">
            <FontAwesomeIcon icon={faGift} style={{ marginRight: '10px' }} />
            {t("languagePage.daily.word.pack")}
          </h2>
          
          <div className="wordpack-card">
            <div className="pack-shine"></div>
            <div className="wordpack-image">
              <img 
                src="/images/wordpack.webp" 
                alt={t("LanguagePage.word.pack")} 
                onError={(e) => e.target.src = '/images/default_pack.png'} 
              />
            </div>
            <div className="wordpack-title">
              {t("languagePage.daily.word.pack")}
            </div>
            <div className="wordpack-description">
              {t("languagePage.wordpack.description", { 
                [currentLanguageParams.language]: language,
                language: language,
                idioma: language,
                langue: language,
                lingua: language,
                sprache: language,
                язык: language,
                γλώσσα: language,
                言語: language,
                स्तर: language,
                مستوى: language,
                antas: language,
                Language: language.charAt(0).toUpperCase() + language.slice(1),
                Idioma: language.charAt(0).toUpperCase() + language.slice(1),
                Langue: language.charAt(0).toUpperCase() + language.slice(1),
                Lingua: language.charAt(0).toUpperCase() + language.slice(1),
                Sprache: language.charAt(0).toUpperCase() + language.slice(1),
                Язык: language.charAt(0).toUpperCase() + language.slice(1),
                Γλώσσα: language.charAt(0).toUpperCase() + language.slice(1),
                Wika: language.charAt(0).toUpperCase() + language.slice(1)
              })}
              <br />
          
            </div>
            <button 
              className="open-pack-button"
              onClick={handleOpenPack}
              disabled={isOpeningPack}
            >
              {isOpeningPack ? (
                <span>
                  <i className="fa fa-spinner fa-spin"></i> 
                  {t("languagePage.wordpack.generating")}
                </span>
              ) : (
                t("languagePage.wordpack.openPack")
              )}
            </button>
          </div>
        </div>

        <div className="past-packs-section fade-in">
          <h3 className="past-packs-header">
            <FontAwesomeIcon icon={faHistory} style={{ marginRight: '8px' }} />
            {t("languagePage.past.packs")}
          </h3>
          <div className="past-packs-container">
            {pastPacks.map((pack, index) => (
              <div 
                key={index} 
                className="past-pack-card"
                onClick={() => {
                  setCurrentPhrases(pack.phrases);
                  setSelectedContext(pack.context);
                  setShowPackModal(true);
                }}
              >
                <div className="past-pack-date">
                  {new Date(pack.opened_at).toLocaleDateString()}
                </div>
                <div className="past-pack-level">
                  Level {pack.level}
                </div>
                {pack.context && (
                  <div className="past-pack-context">
                    {pack.context}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="conversation-section fade-in">
        <h2 className="conversation-header">
          <FontAwesomeIcon icon={faComments} style={{ marginRight: '10px' }} />
          {t('languagePage.conversation.section.title')}
        </h2>
        
        <div className="conversation-card" onClick={handleStartConversation}>
          <div className="conversation-image">
            <img 
              src="/images/ai-convo.webp" 
              alt={t("languagePage.conversation.section.imageAlt")} 
              onError={(e) => e.target.src = '/images/default_conversation.png'} 
            />
          </div>
          <div className="conversation-info">
            <div className="conversation-name">
              {t('languagePage.conversation.section.cardTitle')}
            </div>
            <div className="conversation-description">
              {t('languagePage.conversation.section.description')}
            </div>
          </div>
        </div>
      </div>

      <WordPackIntroModal
        isOpen={showIntroModal}
        onClose={() => {
          console.log('Closing intro modal');
          setShowIntroModal(false);
          setHasSeenIntro(true);
          localStorage.setItem('wordPackIntroSeen', 'true');
        }}
        language={language}
      />

      <ContextSelectionModal
        isOpen={showContextModal}
        onClose={() => setShowContextModal(false)}
        onSelectContext={handleContextSelection}
      />

      <WordPackModal
        isOpen={showPackModal}
        onClose={() => {
          setIsClosing(true);
          setShowPackModal(false);
          setTimeout(() => {
            setIsClosing(false);
          }, 300);
        }}
        phrases={currentPhrases}
        language={language}
        context={selectedContext}
      />

      <DailyLimitModal
        isOpen={showDailyLimitModal}
        onClose={() => setShowDailyLimitModal(false)}
        nextAvailableTime={nextAvailableTime}
      />
    </div>
  );
}

export default LanguagePage;

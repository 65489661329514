import React, { useState, useCallback, useContext, useEffect, useRef } from "react";
import { doc, getDoc, onSnapshot, updateDoc, setDoc, collection, query, where, getDocs, deleteDoc } from "firebase/firestore";
import { firestore } from "../../config/firebase";
import { AuthContext } from "../../contexts/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import './StoryChain.css';
import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const INACTIVITY_WARNING_TIMEOUT = 20000; // 20 seconds

const LEAVE_PAGE_TIMEOUT = 10000; // 10 seconds

const StoryChain = () => {
  const { language } = useParams();
  const navigate = useNavigate();
  const [chain, setChain] = useState([]);
  const [newWord, setNewWord] = useState("");
  const { user } = useContext(AuthContext);
  const [turn, setTurn] = useState(null);
  const [connectedUser, setConnectedUser] = useState(null);
  const [gameFinished, setGameFinished] = useState(false);
  const [searching, setSearching] = useState(false);
  const [gameFound, setGameFound] = useState(false); // Add this state to control game visibility
  const [gameId, setGameId] = useState(null);
  const maxWords = 15;
  const [completedBy, setCompletedBy] = useState(null);
  const [showInactivityWarning, setShowInactivityWarning] = useState(false);
  const lastActiveTimeRef = useRef(Date.now());
  const inactivityTimerRef = useRef(null);
  const [inactivityCountdown, setInactivityCountdown] = useState(10);
  const [otherPlayerName, setOtherPlayerName] = useState(null);
  const { t } = useTranslation();
  const [searchTimeout, setSearchTimeout] = useState(false);

  
  const updateLastActiveTime = useCallback(async () => {
    if (gameId && user?.display_name) {
      const gameRef = doc(firestore, "games", language, "games", gameId);
      await updateDoc(gameRef, {
        [`lastActiveTimes.${user.display_name}`]: Date.now()
      });
    }
  }, [gameId, user?.display_name, language]);

  const checkInactivity = useCallback(() => {
    if (turn === user?.display_name && !gameFinished) {
      const currentTime = Date.now();
      const inactiveTime = currentTime - lastActiveTimeRef.current;
      console.log(`Inactive time for ${user.display_name}: ${inactiveTime / 1000} seconds`);
      if (inactiveTime > INACTIVITY_WARNING_TIMEOUT) {
        console.log(`Showing inactivity warning for ${user.display_name}`);
        setShowInactivityWarning(true);
        setInactivityCountdown(10);
      } else {
        inactivityTimerRef.current = setTimeout(checkInactivity, 1000);
      }
    }
  }, [user?.display_name, turn, gameFinished]);

  const updateActivityTime = useCallback(() => {
    if (!showInactivityWarning && turn === user?.display_name && !gameFinished) {
      lastActiveTimeRef.current = Date.now();
      setInactivityCountdown(10);
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
      inactivityTimerRef.current = setTimeout(checkInactivity, 1000);
      updateLastActiveTime();
    }
  }, [showInactivityWarning, turn, user?.display_name, gameFinished, checkInactivity, updateLastActiveTime]);

  const confirmStillActive = useCallback(() => {
    if (turn === user?.display_name && !gameFinished) {
      lastActiveTimeRef.current = Date.now();
      setShowInactivityWarning(false);
      setInactivityCountdown(10);
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
      inactivityTimerRef.current = setTimeout(checkInactivity, 1000);
      updateLastActiveTime();
    }
  }, [turn, user?.display_name, gameFinished, checkInactivity, updateLastActiveTime]);

  const findOrCreateGame = useCallback(async () => {
    let timeoutId;
    let currentGameRef = null;

    const cleanupOldGame = async () => {
      if (gameId) {
        console.log("Cleaning up old game:", gameId);
        const oldGameRef = doc(collection(doc(firestore, "games", language), "games"), gameId);
        try {
          await deleteDoc(oldGameRef);
          console.log("Successfully deleted old game");
        } catch (error) {
          console.error("Error deleting old game:", error);
        }
      }
    };

    try {
      await cleanupOldGame();
      console.log("Starting game search...");
      setSearching(true);
      setSearchTimeout(false);
      setGameFound(false);
      setGameId(null);

      // Create a promise that rejects after 15 seconds
      const timeoutPromise = new Promise((_, reject) => {
        console.log("Setting up timeout promise...");
        timeoutId = setTimeout(() => {
          console.log("Search timeout triggered!");
          if (currentGameRef) {
            // Delete the game if we created one
            deleteDoc(currentGameRef).catch(error => 
              console.error("Error deleting timed out game:", error)
            );
          }
          setSearching(false);
          setSearchTimeout(true);
          setGameFound(false);
          reject(new Error('timeout'));
        }, 5000);
      });

      // Create the game search promise
      const searchPromise = (async () => {
        console.log("Starting search promise...");
        const languageRef = doc(firestore, "games", language);
        const languageDoc = await getDoc(languageRef);
        if (!languageDoc.exists()) {
          console.log("Creating new language document");
          await setDoc(languageRef, {});
        }

        const gamesRef = collection(languageRef, "games");
        
        // Look for games to join
        console.log("Querying for available games...");
        const availableGamesQuery = query(
          gamesRef,
          where("numPlayers", "==", 1),
          where("finished", "==", false)
        );
        const querySnapshot = await getDocs(availableGamesQuery);

        // Filter out games where the user is already a player
        const availableGames = querySnapshot.docs.filter(doc => {
          const gameData = doc.data();
          return !gameData.players.includes(user.display_name);
        });
        console.log("Found available games:", availableGames.length);

        let currentGameId;
        let gameData;
        let isNewGame = false;

        if (availableGames.length === 0) {
          console.log("No available games found, creating new game");
          currentGameId = `game${Date.now()}`;
          currentGameRef = doc(gamesRef, currentGameId);
          gameData = {
            language,
            chain: [],
            players: [user.display_name],
            numPlayers: 1,
            turn: null,
            finished: false,
            lastActiveTimes: {
              [user.display_name]: Date.now()
            }
          };
          await setDoc(currentGameRef, gameData);
          isNewGame = true;
          console.log("New game created with ID:", currentGameId);
        } else {
          console.log("Joining existing game");
          const gameDoc = availableGames[0];
          currentGameId = gameDoc.id;
          currentGameRef = gameDoc.ref;
          gameData = gameDoc.data();
          
          await updateDoc(currentGameRef, {
            players: [...gameData.players, user.display_name],
            numPlayers: 2,
            turn: gameData.players[0],
            [`lastActiveTimes.${user.display_name}`]: Date.now()
          });
          console.log("Successfully joined game:", currentGameId);
        }

        // After creating or finding a game, start a listener for second player
        const unsubscribe = onSnapshot(currentGameRef, (snapshot) => {
          const data = snapshot.data();
          if (data?.players.length === 2) {
            clearTimeout(timeoutId); // Clear timeout if second player joins
            unsubscribe(); // Clean up listener
            const otherPlayer = data.players.find(p => p !== user.display_name);
            setConnectedUser(otherPlayer);
            setOtherPlayerName(otherPlayer);
            setSearching(false);
            setGameFound(true);
          }
        });

        // Return both the game data and the unsubscribe function
        return { currentGameId, gameData, isNewGame, unsubscribe };
      })();

      try {
        const result = await Promise.race([searchPromise, timeoutPromise]);
        if (result) {
          const { currentGameId, gameData, isNewGame, unsubscribe } = result;
          
          // If we created a new game, keep searching state
          if (isNewGame) {
            setGameId(currentGameId);
            setSearching(true);
            
            // Set up a timeout to show "Retry later" if no one joins
            setTimeout(() => {
              unsubscribe(); // Clean up the listener
              if (currentGameRef) {
                deleteDoc(currentGameRef).catch(error => 
                  console.error("Error deleting game:", error)
                );
              }
              setSearching(false);
              setSearchTimeout(true);
              setGameFound(false);
            }, 5000);
          } else {
            // We joined an existing game
            setGameId(currentGameId);
            setConnectedUser(gameData.players[0]);
            setTurn(gameData.players[0]);
            setSearching(false);
            setGameFound(true);
          }
        }
      } catch (error) {
        // Handle timeout
        console.log("Search timed out or failed:", error);
        if (currentGameRef) {
          await deleteDoc(currentGameRef);
        }
        setSearching(false);
        setSearchTimeout(true);
        setGameFound(false);
      }
    } catch (error) {
      console.error("Error in findOrCreateGame:", error);
      if (currentGameRef) {
        await deleteDoc(currentGameRef);
      }
      setSearching(false);
      setSearchTimeout(true);
      setGameFound(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [language, user, gameId]);

  const handleSearchNewGame = () => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }
    if (window.leavePageTimer) {
      clearTimeout(window.leavePageTimer);
    }
    
    lastActiveTimeRef.current = Date.now();
    
    setSearching(true);
    setChain([]);
    setConnectedUser(null);
    setTurn(null);
    setGameFinished(false);
    setShowInactivityWarning(false);
    setGameId(null);
    findOrCreateGame();
  };

  const handleBack = () => {
    navigate(`/language/${language.toLowerCase()}`);
  };

  const handleAddWord = async () => {
    updateActivityTime();
    const wordRegex = /^\w+$/;
    if (!wordRegex.test(newWord.trim())) {
      alert(t('games.storyChain.input.error'));
      return;
    }

    if (newWord.trim() && user && turn === user.display_name && chain.length < maxWords) {
      const gameRef = doc(firestore, "games", language, "games", gameId);

      try {
        await updateDoc(gameRef, {
          chain: [...chain, { word: newWord, user: user.display_name }],
          turn: await getNextTurn(language, gameId),
        });
        setNewWord("");
        updateLastActiveTime();
      } catch (error) {
        console.error("Error updating the story chain:", error);
      }
    } else {
      alert(t('games.storyChain.status.invalidTurn'));
    }
  };

  const getNextTurn = async (language, gameId) => {
    const gameRef = doc(firestore, "games", language, "games", gameId);
    const gameSnap = await getDoc(gameRef);
    const players = gameSnap.data()?.players || [];

    const currentPlayerIndex = players.indexOf(turn);
    const nextPlayerIndex = (currentPlayerIndex + 1) % players.length;
    return players[nextPlayerIndex];
  };

  const handleFinishGame = async () => {
    try {
      const gameRef = doc(firestore, "games", language, "games", gameId);
      const gameSnap = await getDoc(gameRef);
      const gameData = gameSnap.data();

      if (!gameData || gameData.finished) {
        console.log('Game has already been logged as finished.');
        return;
      }

      await updateDoc(gameRef, {
        finished: true,
        completedBy: user.display_name,
      });

      await fetch(`${apiUrl}/log_story_chain_game`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          player1_display_name: gameData.players[0],
          player2_display_name: gameData.players[1],
          game_name: 'Story Chain',
          language: language,
        }),
      });

      setGameFinished(true);
      setCompletedBy(user.display_name);
    } catch (error) {
      console.error("Error finishing game:", error);
    }
  };

  const handleInactivity = useCallback(async () => {
    if (gameId) {
      const gameRef = doc(firestore, "games", language, "games", gameId);
      await updateDoc(gameRef, {
        finished: true,
        completedBy: "Disconnected",
      });
      setGameFinished(true);
      setCompletedBy("Disconnected");
      setShowInactivityWarning(false);
    }
  }, [gameId, language]);

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden && gameFound && !gameFinished && turn === user?.display_name) {
      console.log(`${user.display_name} left the page during their turn`);
      window.leavePageTimer = setTimeout(() => {
        handleInactivity();
      }, LEAVE_PAGE_TIMEOUT);
    } else {
      console.log(`${user.display_name} returned to the page`);
      if (window.leavePageTimer) {
        clearTimeout(window.leavePageTimer);
      }
    }
  }, [user.display_name, handleInactivity, gameFinished, gameFound, turn]);

  useEffect(() => {
    if (gameFound && !gameFinished && turn === user?.display_name) {
      console.log(`Inactivity check started for ${user.display_name}`);
      lastActiveTimeRef.current = Date.now(); // Reset the timer when it becomes the user's turn
      inactivityTimerRef.current = setTimeout(checkInactivity, 1000);

      return () => {
        if (inactivityTimerRef.current) {
          clearTimeout(inactivityTimerRef.current);
        }
      };
    }
  }, [gameFound, gameFinished, user?.display_name, turn, checkInactivity]);

  useEffect(() => {
    if (showInactivityWarning) {
      const inactivityTimeout = setTimeout(() => {
        handleInactivity();
      }, 5000); // Wait 5 seconds after showing the warning

      return () => clearTimeout(inactivityTimeout);
    }
  }, [showInactivityWarning, handleInactivity]);

  useEffect(() => {
    if (gameFound && !gameFinished) {
      const intervalId = setInterval(() => {
        updateLastActiveTime();
      }, 60000); // Update every minute

      return () => clearInterval(intervalId);
    }
  }, [gameFound, gameFinished, updateLastActiveTime]);

  useEffect(() => {
    if (gameFound && !gameFinished) {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
        if (window.leavePageTimer) {
          clearTimeout(window.leavePageTimer);
        }
      };
    }
  }, [gameFound, gameFinished, handleVisibilityChange]);

  const InactivityWarningModal = ({ onConfirm, countdown }) => (
    <div className="inactivity-warning-modal">
      <div className="inactivity-warning-content">
        <h2>Are you still there?</h2>
        <p>The game will end in {countdown} seconds due to inactivity.</p>
        <button onClick={onConfirm} className="storychain-button">I'm still here!</button>
      </div>
    </div>
  );

  return (
    <div className="storychain-container">
      {!gameFound && !searching && !searchTimeout ? (
        <div className="storychain-intro">
          <h1 className="storychain-title">{t('games.storyChain.title')}</h1>
          <div className="storychain-logo-container">
            <img 
              src="/images/story_chain_icon.webp" 
              alt={t('games.storyChain.title')} 
              className="storychain-logo" 
            />
          </div>
          <p className="storychain-description">
            {t('games.storyChain.description')}
          </p>
          <ul className="storychain-rules">
            {t('games.storyChain.rules.items', { returnObjects: true, maxWords }).map((rule, index) => (
              <li key={index}>{rule}</li>
            ))}
          </ul>
          <button onClick={handleSearchNewGame} className="storychain-button">
            {t('games.storyChain.buttons.startNew')}
          </button>
        </div>
      ) : searchTimeout ? (
        <div className="storychain-timeout">
          <h2>{t('games.storyChain.status.noPlayersFound')}</h2>
          <p>{t('games.storyChain.status.tryAgainLater')}</p>
          <div className="storychain-timeout-buttons">
            <button onClick={handleSearchNewGame} className="storychain-button">
              {t('games.storyChain.buttons.searchNewGame')}
            </button>
            <button onClick={handleBack} className="storychain-button storychain-button-secondary">
              {t('games.storyChain.buttons.back')}
            </button>
          </div>
        </div>
      ) : gameFinished ? (
        <div className="storychain-finish">
          {completedBy === "Disconnected" ? (
            <>
              <p>{t('games.storyChain.status.disconnected')}</p>
              <div className="storychain-final-sentence">
                <h3>{t('games.storyChain.status.finalSentence')}</h3>
                <p>{chain.map(item => item.word).join(' ')}</p>
              </div>
              <button onClick={handleSearchNewGame} className="storychain-button">
                {t('games.storyChain.buttons.searchNewGame')}
              </button>
            </>
          ) : (
            <>
              <p>
                {completedBy === user.display_name
                  ? t('games.storyChain.status.completed.you')
                  : t('games.storyChain.status.completed.other', { player: completedBy || otherPlayerName })}
              </p>
              <div className="storychain-final-sentence">
                <h3>{t('games.storyChain.status.finalSentence')}</h3>
                <p>{chain.map(item => item.word).join(' ')}</p>
              </div>
              <button onClick={handleSearchNewGame} className="storychain-button">
                {t('games.storyChain.buttons.searchNewGame')}
              </button>
            </>
          )}
        </div>
      ) : searching ? (
        <div className="storychain-searching">
          <h2>{t('games.storyChain.status.searching')}</h2>
          <div className="storychain-spinner"></div>
        </div>
      ) : (
        <>
          {!gameFound && (
            <button onClick={handleSearchNewGame} className="storychain-button">
              Search for Match
            </button>
          )}

          {gameFound && connectedUser && (
            <>
              <p className="connected-message">Connected with {connectedUser}</p>

              <div className="storychain-chain">
                {chain.map((item, index) => (
                  <span
                    key={index}
                    className={`storychain-word ${item.user === user.display_name ? "user-word" : "connected-user-word"}`}
                  >
                    {item.word + " "}
                  </span>
                ))}
              </div>

              {chain.length < maxWords ? (
                <>
                  <div className="storychain-input-container">
                    <input
                      type="text"
                      value={newWord}
                      onChange={(e) => {
                        setNewWord(e.target.value);
                        updateActivityTime();
                      }}
                      placeholder="Add a word"
                      className="storychain-input"
                      maxLength={20}
                    />
                    <button onClick={handleAddWord} className="storychain-button">
                      Submit Word
                    </button>
                  </div>
                  {turn === user?.display_name ? (
                    <p className="storychain-turn-indicator">Your turn!</p>
                  ) : (
                    <p className="storychain-turn-indicator">Waiting for {connectedUser} to take their turn...</p>
                  )}
                </>
              ) : (
                <div className="storychain-finish">
                  <p>The story has reached {maxWords} words!</p>
                </div>
              )}

              <div className="storychain-finish-button-container">
                <button onClick={handleFinishGame} className="storychain-finish-button">
                  Finish Game
                </button>
              </div>

              {/* Add this note for testing */}
            
            </>
          )}
        </>
      )}
      {showInactivityWarning && (
        <div className="inactivity-warning-overlay" onClick={(e) => e.stopPropagation()}>
          <InactivityWarningModal onConfirm={confirmStillActive} countdown={inactivityCountdown} />
        </div>
      )}
    </div>
  );
};

export default StoryChain;
